import { Suspense } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { DataGuardiansPage } from "pages/data-guardians";
import { DevPage } from "pages/dev";
import { ErrorPage } from "pages/error";
import { KYCGuardiansPage } from "pages/kyc-guardians";
import { MyCertificatesPage } from "pages/my-certificates";
import { MyProofsPage } from "pages/my-proofs";
import { MySBTsPage } from "pages/my-sbts";
import { PartnersPage } from "pages/partners";
import { PassportPage } from "pages/passport";
import { SoulMatrixPage } from "pages/soul-matrix";
import { Layout } from "pages/ui";
import { featuresFlags } from "shared/config";
import { RqProvider } from "shared/providers/rq";
import { WagmiProvider } from "shared/providers/wagmi";

import { AuthGuard } from "./guards/auth";
import { Root } from "./root";

import "react-toastify/dist/ReactToastify.min.css";

import "./index.css";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorPage />}>
      <Route element={<Layout isDrawer />}>
        <Route element={<AuthGuard isMetamaskNeeded isSnapNeeded />}>
          <Route element={<PassportPage />} path="/" />
          {import.meta.env.DEV && <Route element={<DevPage />} path="/dev" />}
          <Route element={<SoulMatrixPage />} path="/soul-matrix" />
        </Route>
        <Route element={<div>Not found</div>} path="*" />
      </Route>
      <Route element={<Layout />}>
        <Route element={<PartnersPage />} path="/ecosystem" />
        <Route element={<DataGuardiansPage />} path="/data-guardians" />
        <Route element={<KYCGuardiansPage />} path="/kyc-guardians" />
        <Route element={<AuthGuard isMetamaskNeeded />}>
          <Route element={<MySBTsPage />} path="/my-sbts" />
        </Route>
        <Route element={<AuthGuard isMetamaskNeeded isSnapNeeded />}>
          <Route element={<MyCertificatesPage />} path="/my-certificates" />
          {featuresFlags[import.meta.env.VITE_CHAIN_ID].proofs && (
            <Route element={<MyProofsPage />} path="/my-proofs" />
          )}
        </Route>
        <Route element={<div>Not found</div>} path="*" />
      </Route>
    </Route>
  )
);

export const App = () => {
  return (
    <WagmiProvider>
      <RqProvider>
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </RqProvider>
    </WagmiProvider>
  );
};
