import { useEffect } from "react";

import { ZodError } from "zod";

import { useEventCallback } from "shared/hooks";

import { CertSchema, certSchema } from "./schema";
import { Cert } from "./types";
import { useCerts } from "./use-certs";

type Params = {
  onError: (cert: Cert, error: ZodError<CertSchema>) => void;
};

export const useUnsupportedCerts = (params: Params) => {
  const { certs } = useCerts();

  const errorEvent = useEventCallback(params.onError);

  useEffect(() => {
    certs.forEach((cert) => {
      const result = certSchema.safeParse(cert);

      if (result.error) errorEvent(cert, result.error);

      return result.success;
    });
  }, [certs, errorEvent]);
};
