import { z } from "zod";

export const certSchema = z.object({
  expirationDate: z.number(),
  expirationDateMS: z.number(),
  standard: z.string(),
  verificationLevel: z.string().optional(),
  providerPubKey: z.object({
    ax: z.string(),
    ay: z.string(),
  }),
});

export type CertSchema = z.infer<typeof certSchema>;
